import WOW from 'wowjs';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import React, { useEffect, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { CopyToClipboard } from 'react-copy-to-clipboard';


import Navbar from '../../components/navbar';

import './index.css';
import 'animate.css';
import '../../static/css/animate.css';

const Home = () => {

    const [activeSection, setActiveSection] = useState('');
    const [isShown, setShow] = useState(false);
    const [textToCopy, setTextToCopy] = useState(''); // The text you want to copy
    const [copyStatus, setCopyStatus] = useState(false); // To indicate if the text was copied
    useEffect(() => { new WOW.WOW({ live: true }).init() }, []);

    const setVisible = (active) => {
        if (activeSection === active) setActiveSection('');
        else setActiveSection(active);
    };

    const onCopyText = () => {
        setCopyStatus(true);
        setTimeout(() => setCopyStatus(false), 2000); // Reset status after 2 seconds
    };


    return (
        <div className='epic-fail-page'>
            <div>
                
                {isShown
                ? 
                <>
                    {/* Epic Fail */}
                    <VisibilitySensor onChange={() => setVisible('Epic Fail')}>
                        <section className="epic-section" id="epic">
                            <Navbar activeSection={activeSection} />
                            <div className='video-bg'>
                                <img src={require("../../static/images/epic-fail.gif")} alt="" />
                                <div className='text-box'>
                                    <h2>Winning by Failing</h2>
                                </div>
                            </div>
                        </section>
                    </VisibilitySensor>

                    {/* Toknomics Section */}
                    <VisibilitySensor onChange={() => setVisible('Toknomics')}>
                        <section className='toknomics-sec' id="toknomics-sec">
                            <div className='auto-container'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='sec-title wow zoomIn'>
                                            <h2>TOKENOMIC</h2>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-12'>
                                        <div className='toknomics-box wow fadeInLeft'>
                                            <div className='text-box'>
                                                <h4>Token Address</h4>
                                                <p>9gCJB....pUMp</p>
                                                <CopyToClipboard text='9gCJBVuhT4uFtwaBVnB6GGcsC2wTYsJZm48REdspUMp' onCopy={onCopyText}>
                                                    <button className='icon'><i><img src={require("../../static/images/file.png")} alt="" /></i></button>
                                                </CopyToClipboard>
                                                {copyStatus && <p style={{ marginTop: '-12px', fontSize: '9px' }}>Wallet Address Copied</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-12'>
                                        <div className='toknomics-box wow fadeInUp'>
                                            <div className='text-box'>
                                                <h4>100% LP Burnt</h4>
                                                <i className='icon'><img src={require("../../static/images/fire.png")} alt="" /></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-12'>
                                        <div className='toknomics-box wow fadeInDown'>
                                            <div className='text-box'>
                                                <h4>Total Supply</h4>
                                                <p>100 billion</p>
                                                <i className='icon'><img src={require("../../static/images/doller.png")} alt="" /></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-12'>
                                        <div className='toknomics-box wow fadeInRight'>
                                            <div className='text-box'>
                                                <h4>Mint Authority <br />Revoked</h4>
                                                <i className='icon'><img src={require("../../static/images/user.png")} alt="" /></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </VisibilitySensor>

                    {/* fail Section */}
                    <VisibilitySensor onChange={() => setVisible('Fail')}>
                        <section className='fail-sec' id="fail-sec">
                            <div className='auto-container'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='sec-title'>
                                            <h2>The Coin That Fails So You Don’t Have To<span>!</span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='animation-box'>
                                <div className='train-img'>
                                    <img src={require("../../static/images/train.png")} alt="" />
                                </div>
                                <img className='main-img' src={require("../../static/images/animation-box.png")} alt="" />
                                <div className='chartor-img'>
                                    <img src={require("../../static/images/chartor-1.png")} alt="" />
                                </div>
                                <div className='chartor-img-2'>
                                    <img src={require("../../static/images/chartor-2.png")} alt="" />
                                </div>
                            </div>
                        </section>
                    </VisibilitySensor>

                    {/* Join Section */}
                    <VisibilitySensor onChange={() => setVisible('Join')}>
                        <section className='join-sec' id="joinme">
                            <div className='auto-container'>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12'>
                                        <div className='sec-title wow zoomIn'>
                                            <h1>EPIC FAIL </h1>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 order-area">
                                        <div className="heading-memes-area-left wow fadeIn">
                                            <div className='left-img'>
                                                <img src={require("../../static/images/left-img.png")} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className='sec-title wow zoomIn'>
                                            <h2>join the <br /><span>community</span></h2>
                                            <h3>  </h3>
                                            <ul>
                                                <li>
                                                    <a className='nav-link' href='https://t.me/EpicFailCoin' target="_blank"><img src={require('../../static/images/telegram.png')} alt='' /></a>
                                                </li>
                                                <li>
                                                    <a className='nav-link' href='https://x.com/EpicFailCoin' target='_blank'><img src={require('../../static/images/x.png')} alt='' /></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </VisibilitySensor>
                </>
                :<>
                    {/* Banner */}
                    <VisibilitySensor onChange={() => setVisible('Banner')}>
                        <section className="banner-section" id="home">
                            <Navbar activeSection={activeSection} />
                            <div className='video-bg'>
                                <img src={require("../../static/images/error.gif")} alt="" />
                            </div>
                            <div className="auto-container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className='bottom-arrow-area'>
                                            <button onClick={() => setShow(!isShown)} className='bottom-arrow'>
                                                <img src={require("../../static/images/bottom-arrow.gif")} alt="" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </VisibilitySensor>
                </>
                }
            </div>
        </div >
    );
}

export default Home;